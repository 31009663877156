<template>
  <div class="setup-page">
    <Header activeTab="setup" :isOpaque="true" />

    <div class="below-header">
      <div class="content">
        <h1 class="main-heading">First, create your account.</h1>
        <LoginRegister />
        <h1 class="main-heading">Then, register your app to obtain a secret.</h1>
        <div class="apps-container" v-if="isLoggedIn">
          <Apps />
        </div>
        <h1 class="main-heading">Finally, add locality_social_cloud to your project.</h1>
        <p class="instruction">
          Write flutter pub add locality_social_cloud && flutter pub get.
        </p>
      </div>
      <div class="mt-8 text-center">
        <a href="/documentation" class="custom-button">
          Check out documentation
        </a>
      </div>
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue';
import Apps from './Apps.vue';
import LoginRegister from '@/components/LoginRegister.vue';
import { mapGetters } from 'vuex';

export default {
  name: "Setup",
  components: {
    Header,
    Apps,
    LoginRegister
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  data() {
    return {
      drawer: false,
      group: null,
      items: [],
      sections: [
        { 
          id: 'setup', 
          title: 'Setup', 
          subsections: [
            { id: 'setup-subsection1', title: '1) Create an Account', component: () => import('./documentation/SetupSubsection1.vue') },
            { id: 'setup-subsection2', title: '2) Register Your App', component: () => import('./documentation/SetupSubsection2.vue') },
            { id: 'setup-subsection3', title: '3) Add locality_social_cloud to your project', component: () => import('./documentation/SetupSubsection3.vue') },
          ] 
        },
      ],
      currentSection: ''
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    scrollToSection(id) {
      const headerOffset = 80;
      const element = document.getElementById(id);
      const offsetPosition = element.offsetTop - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    },
    updateCurrentSection() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;

      for (let section of this.sections) {
        const element = document.getElementById(section.id);
        if (element) {
          const offsetTop = element.offsetTop - 80;
          const offsetHeight = element.offsetHeight;

          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            this.currentSection = section.id;
            break;
          }
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateCurrentSection);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateCurrentSection);
  }
};
</script>

<style scoped>
.setup-page {
  background-color: #03001e;
  color: #fdeff9;
}

.below-header {
  padding-left: 20px;
  padding-right: 20px;
}

.content {
  padding-top: 80px; /* Reduced from 120px */
  text-align: left;
  margin: 0 auto;
  max-width: 800px;
}

.main-heading {
  font-size: 1.8em; /* Increased from 1.5em */
  margin: 30px 0; /* Increased top and bottom margin */
  font-weight: 300; /* Reduced from bold (which is typically 700) */
  color: #fdeff9;
  letter-spacing: 1px; /* Adds some spacing between letters */
}

.apps-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 30px; /* Increased bottom margin */
}

.instruction {
  font-size: 1.2em;
  color: #fdeff9;
}

.custom-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #7303c0;
  color: #fdeff9;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #ec38bc;
}
</style>
