import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginRegister from '@/components/LoginRegister.vue';
import Documentation from '@/views/Documentation.vue';
import Setup from '@/views/Setup.vue';
import Apps from '@/views/Apps.vue';
import AdminPage from '@/views/AdminPage.vue';
import OurStory from '@/views/OurStory.vue';
import GDPR from '@/views/GDPR.vue';
import Impressum from '@/views/Impressum.vue';
import DataProtection from '@/views/DataProtection.vue';
import ViewSingleAppPage from '@/views/ViewSingleAppPage.vue';
import Pricing from '@/components/Pricing.vue';
import Contact from '@/views/Contact.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Locality Social Cloud',
      metaTags: [
        {
          name: 'Locality Social Cloud',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        },
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/gdpr',
    name: 'GDPR',
    component: GDPR
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'DataProtection',
    component: DataProtection
  },
  {
    path: '/login',
    name: 'LoginRegister',
    component: LoginRegister
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation
  },
  {
    path: '/adminpage',
    name: 'AdminPage',
    component: AdminPage
  },
  {
    path: '/setup',
    name: 'Setup',
    component: Setup
  },
  {
    path: '/apps',
    name: 'Apps',
    component: Apps
  },
  {
    path: '/ourStory',
    name: 'OurStory',
    component: OurStory
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/app/:app_id',
    name: 'ViewSingleAppPage',
    component: ViewSingleAppPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
