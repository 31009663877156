<template>
  <header :class="headerClasses" :style="headerStyle">
    <div class="container mx-auto flex items-center justify-between">
      <!-- Navigation Links -->
      <nav class="hidden md:flex md:flex-row items-center md:mr-auto md:ml-4 md:pl-4 md:border-l md:border-purple-500">
        <a href="/" :class="{ 'active-tab': activeTab === 'locality' }" class="nav-link mr-6 hover:text-orange-500">Locality</a>
        <a href="/setup" :class="{ 'active-tab': activeTab === 'setup' }" class="nav-link mr-6 hover:text-orange-500">Setup</a>
        <a href="/documentation" :class="{ 'active-tab': activeTab === 'documentation' }" class="nav-link mr-6 hover:text-orange-500">Documentation</a>
        <a @click="closeMenu" href="/pricing" :class="{ 'active-tab': activeTab === 'pricing' }" class="nav-link mr-6 hover:text-orange-500">Pricing</a>

      </nav>
      
      <!-- Login Button -->
      <login-button class="hidden md:block"></login-button>

      <!-- Burger Menu Button -->
      <button 
        @click="toggleMenu" 
        class="md:hidden text-black focus:outline-none"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>
    </div>

    <!-- Fullscreen Mobile Menu -->
    <transition name="fade">
      <div v-if="isMenuOpen" class="fixed inset-0 bg-purple-900 z-50 flex flex-col justify-center items-center">
        <button @click="toggleMenu" class="absolute top-5 right-5 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <nav class="text-center">
          <a @click="closeMenu" href="/" :class="{ 'active-tab': activeTab === 'locality' }" class="block text-2xl text-white hover:text-orange-500">Locality</a>
          <a @click="closeMenu" href="/setup" :class="{ 'active-tab': activeTab === 'setup' }" class="block text-2xl text-white hover:text-orange-500">Setup</a>
          <a @click="closeMenu" href="/documentation" :class="{ 'active-tab': activeTab === 'documentation' }" class="block text-2xl text-white hover:text-orange-500">Documentation</a>
          <a @click="closeMenu" href="/pricing" :class="{ 'active-tab': activeTab === 'pricing' }" class="block text-2xl text-white hover:text-orange-500">Pricing</a>
        </nav>
        <div class="mt-8">
          <login-button></login-button>
        </div>
      </div>
    </transition>
  </header>
  <main :style="mainContentStyle">
    <slot></slot>
  </main>
</template>

<script>
import LoginButton from './LoginButton.vue';

export default {
  name: 'Header',
  components: {
    LoginButton
  },
  props: {
    theme: {
      type: String,
      default: 'dark'
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    headerClasses() {
      return {
        'text-white': true,
        'body-font': true,
        'fixed': true,
        'top-0': true,
        'w-full': true,
        'z-50': true,
        'h-30': true
      };
    },
    headerStyle() {
      return {
        background: 'linear-gradient(to right, #03001e, #7303c0, #ec38bc, #fdeff9)'
      };
    },
    logoColor() {
      return this.theme === 'dark' ? '#111111' : 'currentColor';
    },
    logoTextColor() {
      return this.theme === 'dark' ? '#e4e725' : '#111111';
    },
    headerHeight() {
      return '60px';
    },
    mainContentStyle() {
      return {
        paddingTop: this.headerHeight
      };
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = '';
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  color: #e4e725;
  padding-top: 10px;
}
.container {
  margin-top: 11px;
}
header {
  font-family: 'Montserrat', sans-serif;
  z-index: 50;
  height: 60px;
  padding-right: 15px;
}

a {
  color: white;
  font-size: 14px!important;
  padding-right: 30px;
}

.active-tab {
  color: #ffd16e;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fixed.inset-0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fixed.inset-0 a {
  font-size: 24px !important;
  padding: 15px 0;
}

@media (max-width: 880px) {
  .container {
    justify-content: flex-end;
  }
  
  .logo {
    display: none;
  }

  .nav-link {
    margin-right: 0.5rem;
  }

  .login-link {
    background-color: transparent;
    color: inherit;
    padding: 0;
    text-decoration: none;
    margin-right: 0.5rem;
  }

  .login-link:hover {
    background-color: transparent;
    color: #3d255e;
    text-decoration: underline;
  }

}

@media (min-width: 881px) {
  .nav-links, .login-link {
    font-size: 1rem;
  }


}
</style>