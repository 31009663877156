<template>
  <section class="text-gray-600 body-font overflow-hidden">
    <Header />
    <div class="container px-5 py-24 mx-auto max-w-5xl">
      <div class="flex flex-col text-center w-full mb-20">
        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Pricing</h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
          Choose the plan that suits your needs. Pricing is based on data transfer volumes.
        </p>
        <div class="flex mx-auto border-2 rounded overflow-hidden mt-6">
          <button 
            class="py-1 px-4 focus:outline-none" 
            :class="{ 'bg-indigo-500 text-white': !isAnnual, 'bg-white text-gray-900': isAnnual }"
            @click="isAnnual = false"
          >
            Monthly
          </button>
          <button 
            class="py-1 px-4 focus:outline-none" 
            :class="{ 'bg-indigo-500 text-white': isAnnual, 'bg-white text-gray-900': !isAnnual }"
            @click="isAnnual = true"
          >
            Annually
          </button>
        </div>
      </div>
      <div class="flex flex-wrap -m-4 justify-center">
        <div v-for="(plan, index) in pricingPlans" :key="index" class="p-4 xl:w-1/3 md:w-1/2 w-full">
          <div :class="['h-full p-6 rounded-lg border-2 flex flex-col relative overflow-hidden', plan.borderClass]">
            <h2 class="text-sm tracking-widest title-font mb-1 font-medium">{{ plan.name }}</h2>
            <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
              <span>{{ isAnnual ? plan.annualPrice : plan.monthlyPrice }}</span>
              <span class="text-lg ml-1 font-normal text-gray-500" v-if="plan.name !== 'Enterprise'">
                /{{ isAnnual ? 'year' : 'mo' }}
              </span>
            </h1>
            <p v-for="(feature, idx) in plan.features" :key="idx" class="flex items-center text-gray-600 mb-2">
              <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>{{ feature }}
            </p>
            <a 
              :href="plan.link" 
              @click.prevent="handlePlanClick(plan.link)" 
              class="flex items-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none" 
              :class="plan.buttonClass"
            >
              Select
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
            <p class="text-xs text-gray-500 mt-3">{{ plan.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    <!-- Login Dialog -->
    <v-dialog v-model="loginDialog" max-width="700px">
      <v-card style="height: 620px; border-radius: 52px; padding: 5px; background-color: #02000e;">
        <LoginRegister @close="closeLoginDialog" />
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/Footer.vue';
import LoginRegister from './LoginRegister.vue';

export default {
  name: 'Pricing',
  components: {
    Header,
    Footer,
    LoginRegister
  },
  data() {
    return {
      isAnnual: false, // Toggle for pricing plans
      loginDialog: false,
      redirectAfterLogin: null,
      pricingPlans: [
        {
          name: 'Free',
          monthlyPrice: '0€',
          annualPrice: '0€',
          features: [
            'Up to 500 events/day',
          ],
          description: 'For individuals and small projects.',
          borderClass: 'border-gray-300',
          buttonClass: 'bg-gray-400 hover:bg-gray-500 hidden',
          link: 'https://yourdomain.com/signup/free' // Absolute URL
        },
        {
          name: 'Startup',
          monthlyPrice: '10€',
          annualPrice: '120€',
          features: [
            'Up to 25,000 free events/day',
            'Then 0.03€ for each further 1000 events',
            'Standard Support',
          ],
          description: 'Perfect for growing startups.',
          borderClass: 'border-indigo-500',
          buttonClass: 'bg-indigo-500 hover:bg-indigo-600',
          link: 'https://buy.stripe.com/9AQ9B01no2s4fmgaEE' // Absolute URL
        },
      ]
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapActions(['logout']),
    showLoginDialog() {
      this.loginDialog = true;
    },
    closeLoginDialog() {
      this.loginDialog = false;
      if (this.redirectAfterLogin) {
        window.location.href = this.redirectAfterLogin; // Use window.location.href for absolute URLs
        this.redirectAfterLogin = null;
      }
    },
    handlePlanClick(link) {
      if (this.isLoggedIn) {
        window.location.href = link; // Redirect to the absolute URL
      } else {
        this.redirectAfterLogin = link;
        this.showLoginDialog();
      }
    }
  }
};
</script>

<style scoped>
.login-btn {
  background-color: black;
  color: white;
  padding: 20px;
  width: 200px;
  height: 60px;
}
.v-menu__content {
  z-index: 1000 !important;
  position: absolute !important;
  right: 0 !important;
}
</style>
