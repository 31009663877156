<template>
  <Header activeTab="documentation" isOpaque="true" />
  
<div class="documentation">
  <div class="innerdoc">
    <div class="section">
      <h1>Social Spaces</h1>
      <p>
        Social Apps are fundamentally a technological reflection of real social interactions. 
        In real social interactions, we interact through objective space that transports state changes at light speed.
        The challenge of creating objective spaces for multiple observers across many devices is fundamental. 
        In order to model the observed state of a logical group of data consistently, it is necessary to model the current state 
        as the result of a sequence of order-agnostic transformations from an initial state. 
        Order-agnostic in this sense means: The state you compute should be invariant against permutation of the sequence in which state changes are applied. 
        Traditionally, state management and business logic are performed on servers. 
        This creates significant challenges when dealing with End-to-End-encrypted interactions. 
        With Locality Social Cloud we provide a novel framework of creating objective spaces for many different observers across many different devices 
        with frontend-driven state management. 
        We solve for you hard problems like partial system failures (users going offline), uptime guarantees and caching and provide you with a seamless way 
        for managing state across all of your applications.
      </p>
    </div>

    <div class="section">
      <h2>Project Setup</h2>
      <h3>Create a developer account for your company</h3>
      <p>
        First, register a developer account and an app. You can do that <a href="http://localhost:3000/register">here</a>.
      </p>

      <h3>Add the locality_social_cloud library to Flutter</h3>
      <p>Write</p>
      <pre><code>
        <span class="keyword">flutter pub add locality_social_cloud</span><br>
        <span class="keyword">flutter pub get</span>
      </code></pre>
      <p>to add the Locality Social Cloud to your Flutter App.</p>

      <h3>Configure Locality Social Cloud</h3>
      <p>
        Obtain your app-ID and app secret from your <a href="http://localhost:3000/register">developer account</a> and write
      </p>
      <pre><code>
        <span class="keyword">LocalitySocialCloud.configure</span>(<span class="class-name">app_id</span>: <span class="string">'YOUR APP ID'</span>, <span class="class-name">app_secret</span>: <span class="string">'YOUR APP SECRET'</span>);
      </code></pre>
      <p>to set up your social cloud.</p>
    </div>

    <div class="section">
      <h2>Example</h2>
      We will now see a an example of how to create a global distributed click counter. Many different users observer the topic global-click-counter on their different devices.
      Each user can increment the click counter and each user sees the same amount of clicks.
      Timelines can locally diverge from the global state, but will rapidly synchronize in realtime, once connection is back up.
      <h3><br>Create a controller</h3>
      <pre><code>
        <span class="keyword">class</span> <span class="class-name">ExampleController</span> <span class="keyword">extends</span> <span class="class-name">ChangeNotifier</span> <span class="keyword">with</span> <span class="class-name">PubSub</span> {
          <span class="keyword">int</span> counter = <span class="keyword">0</span>;

          @override
          <span class="keyword">String</span> <span class="function">getTopic</span>() {
            <span class="keyword">return</span> <span class="string">'global-click-counter'</span>;
          }

          <span class="keyword">void</span> <span class="function">increaseCounter</span>(<span class="keyword">int</span> amount) {
            <span class="function">send</span>(<span class="string">'increaseCounter'</span>, {
              <span class="string">'amount'</span>: amount
            });
          }

          @override
          <span class="keyword">void</span> <span class="function">onReceive</span>(<span class="class-name">LocalityEvent</span> localityEvent) {
            <span class="keyword">switch</span>(localityEvent.event) {
              <span class="keyword">case</span> <span class="string">'increaseCounter'</span>:
                counter += localityEvent.payload[<span class="string">'amount'</span>] <span class="keyword">as</span> <span class="keyword">int</span>;
                <span class="function">print</span>(<span class="string">"CLICK COUNTER IS AT "</span> + counter.<span class="function">toString</span>());
                <span class="function">notifyListeners</span>();
                <span class="keyword">break</span>;
            }
          }
        }
      </code></pre>
      This will notify its listeners whenever a user globally clicks the click-counter. For high traffic, throttling should be implemented on the UI side. That is, a Renderer fetches the state at a certain frequency from a state object to rerender this.
      In our next step, we want to notify the UI when a user clicks the click counter.<br>

      <h3><br>Create a view for your controller</h3>
      <pre><code>
        <span class="keyword">class</span> <span class="class-name">CounterView</span> <span class="keyword">extends</span> <span class="class-name">StatelessWidget</span> {
          @override
          <span class="keyword">Widget</span> <span class="function">build</span>(<span class="class-name">BuildContext</span> context) {
            <span class="keyword">final</span> exampleController = <span class="keyword">new</span> <span class="class-name">ExampleController</span>();

            <span class="keyword">return</span> <span class="class-name">ChangeNotifierProvider</span>.value(
              value: exampleController,
              child: <span class="class-name">Scaffold</span>(
                appBar: <span class="class-name">AppBar</span>(
                  title: <span class="class-name">Text</span>(<span class="string">'Click Counter'</span>),
                ),
                body: <span class="class-name">Center</span>(
                  child: <span class="class-name">Column</span>(
                    mainAxisAlignment: <span class="class-name">MainAxisAlignment</span>.center,
                    children: < <span class="keyword">Widget</span> >[
                      <span class="class-name">Text</span>(
                        <span class="string">'You have pushed the button this many times:'</span>,
                      ),
                      <span class="class-name">Consumer</span>< <span class="class-name">ExampleController</span> >(
                        builder: (context, exampleController, child) {
                          <span class="keyword">return</span> <span class="class-name">Text</span>(
                            <span class="string">'${exampleController.counter}'</span>,
                            style: Theme.of(context).textTheme.headline4,
                          );
                        },
                      ),
                      <span class="class-name">SizedBox</span>(height: 20),
                      <span class="class-name">ElevatedButton</span>(
                        onPressed: () {
                          exampleController.<span class="function">increaseCounter</span>(1);
                        },
                        child: <span class="class-name">Text</span>(<span class="string">'Increment Counter'</span>),
                      ),
                    ],
                  ),
                ),
              ),
            );
          }
        }
      </code></pre>

      In this example, we use provider the provider library. It listens to notifications from the State Object to trigger a rebuild of this specific part of the UI. 
      <pre><code>
      Consumer< ExampleController >
      (
        builder: (context, exampleController, child) {
          return Text(
            '${exampleController.counter}',
            style: Theme.of(context).textTheme.headline4,
          );
        },
      ),
      </code></pre>
      <h3>At some point, connect to the Social Cloud to synchronize your state.</h3>
      <pre><code>
        <span class="keyword">LocalitySocialCloud.configure</span>(app_id: 'YOUR APP ID', app_secret: 'YOUR APP SECRET');
        <span class="keyword">LoggedInUser?</span> loggedInUser = <span class="keyword">await</span> Auth.<span class="function">login</span>(<span class="string">"test_user1235"</span>, <span class="string">"pwd"</span>);
        <span class="function">LocalitySocialCloud.connect</span>(loggedInUser!);
      </code></pre>
    </div>
    <div class="section">
      <h2>Examples of Problem Modeling</h2>
      Working with end-to-end-encrypted state management requires a special way of thinking about the problems in an asynchronous way. Here are some examples of how common problems can be modeled with the Locality Social Cloud
      <h3><br>Friend-Requests and FriendList</h3>
      <ul>
        <li>A topic represents all friend requests that a user has sent and received, so each user gets his own topic that lists received friend requests.</li>
        <li>Send an event for each FriendRequest to the topic of the use who is sending the message and another event to the topic of the recipient of the friend request. </li>
        <li>Each friend request contains sender, recipient, public key and target.</li>
        <li>Encrypt the message with the class PayloadCipher and the shared ECDH key for both users via loggedInUser.<span class="function">getKeyFor</span>(otherUser)</li>
        <li>In your PubSub, react to each event by adding it to the list of Friend Request Events and then check whether in your list there are matching pairs.</li>
        <li>If you want you can also add logic to accept friend requests</li>
      </ul>
      <h3><br>Messaging</h3>
      <ul>
        <li>Again, derive a shared topic ID, for example by hashing an alphabepitical ordering of both user names with salt</li>
        <li>When you supervise the toppic, you can set a global ChaChaKey. Use loggedInUser.<span class="function">getKeyFor</span>(otherLoggedInUser) to derive a common key. </li>
        <li>Now, all messages via that topic are end-to-end-encrypted based on the shared key.</li>
        <li>In your PubSub, throw an event whenever a message is sent.</li>
        <li>When processing, you will receive each message that is sent. Append the received message to a local message list state object and notify its observers to trigger UI change, for example.</li>
      </ul>
      <h3><br>Ticketing</h3>
      <ul>
        <li>Create a topic for the tickets belonging to an event type</li>
        <li>There are initially 0 tickets. Create events like addTickets to add a new contingent of tickets</li>
        <li>Create a Buy Event. The Events will be stamped with an authoritative servertime that you can access in the PubSub</li>
        <li>Create a Heap that can keep temporal data in Order and can like timestamps to Buy Events.</li>
        <li>React to Buy Events by inserting them into the Heap ordered by Servertime to determine, who got the ticket first.</li>
        <li>Ensure that the user will see error messages when there are 0 tickets and he attempts to buy.</li>
        <li>Now, locally it is possible for your timeline to diverge, because from the path of the server to the path of the consumption of the events, the events may lose their order. Thus, users may briefly see false states, telling them they bought a ticket when they did not. However, eventual consistency is guaranteed, that is, the timeline will quickly get snychronized with the global authoritative timeline and all observers will agree based on the servertime who bought a ticket first.</li>
      </ul>
      <h3><br>Currency Example</h3>
      <ul>
        <li>Create a topic for a central bank</li>
        <li>You can send events 'Mint Currency' to change the state from currency amount 0</li>
        <li>'Mint currency' events change the currency state of the central bank (global money) and contain the lend target, which is a wallet ID</li>
        <li>It will Also dispatch a 'Currency Minted' event in the wallet topic.</li>
      </ul>
      <ul>
        <li>Create a topic for each wallet</li>
        <li>You can send events 'Pay' to change the state of your currency amount.</li>
        <li>Again, the Pay Events will receive an authoritative timestamp, so all observers of a wallet Topic can agree to which events where sent first</li>
        <li>Ensure that the user does not spend currency if he has none and instead gets an error message.</li>
        <li>Contain something like the good-id in the payload.</li>
        <li>Dispatch a 'Payment Received' event in the Target wallet's topic</li>
        <li>Sign this Payment with ECDSA to verify based on the public key of the User that the user did the payment.</li>
      </ul>
      Generally you can also go for a more straightforward approach, as you can expect users not be able to mess with the topics. 
      They are well signed and protected and need a logged in user to be accessed. Thus, it would require reverse engineering of your Flutter Code and stealing your app_secret
      for the user to be able to send cheat-events like fraudulent payments. The solution above is a very cautious one. Generally you could also just straightforwardly
      <ul>
        <li>Create a topic for each wallet</li>
        <li>You can send events 'Add Money' that you can throw when your backend has received some sort of payment, for example. This would require a dedicated backend.</li>
        <li>You can send events 'Pay' to change the state of your currency amount.</li>
      </ul>
      It is up to your imagination, thinking about solving state management problems with this new framework in an asynchronous way is interesting and insightful. We are working on providing examples
      for many of the basic Use Cases
    </div>

    <div class="section">
      <h2>End-to-End encryption</h2>
      <p>Locality Social Cloud provides End-to-End-Encryption based on ECDH M-511, Sha256 and ChaCha20.</p>

      <h3>Common key for users</h3>
      <pre><code>
        loggedInUser.<span class="function">getKeyFor</span>(otherLoggedInUser);
      </code></pre>

      <h3>Secure a topic</h3>
      <p>You can also encrypt groups. Each topic belongs to the user who registered it first.
      Each key is available on each device of the user. You must send the key to another user via direct-message in order to let him or her participate in a group.</p>
      <pre><code>
        <span class="keyword">void</span> <span class="function">authExample</span>(<span class="class-name">LoggedInUser</span> loggedInUser) <span class="keyword">async</span>
        {
          <span class="class-name">TopicAuth</span> topicAuth = <span class="function">LocalitySocialCloud.loadTopicAuths</span>(loggedInUser);
          topicAuth.<span class="function">secureChannel</span>(
              channel: <span class="string">'globally-unique-topic-id'</span>,
              secret: <span class="string">'generated_secret_password'</span>,
              metadata: {
                <span class="string">'profile_img'</span>: <span class="string">'https://locality.media/yoram'</span>,
                <span class="string">'groupname'</span>: <span class="string">'New Chatgroup'</span>,
                <span class="string">'role'</span>: <span class="string">'Administrator'</span>
              }
          );
          topicAuth.<span class="function">addListener</span>(() {
            <span class="function">print</span>(<span class="string">"LISTING ALL SECURE CHANNELS: "</span>);
            topicAuth.<span class="function">secureChannels.forEach</span>((element) {
              <span class="function">print</span>(<span class="string">"SECURE CHANNEL "</span> + element.<span class="function">toString</span>());
            });
          });
        }
      </code></pre>
    </div>

    <div class="section">
      <h2>Discover users</h2>

      <h3>By Username</h3>
      <pre><code>
        <span class="class-name">DiscoverUsers</span> discoverUsers = <span class="function">LocalitySocialCloud.discoverUsers</span>();
        discoverUsers.<span class="function">startingWith</span>(<span class="string">'Maltii'</span>);

        discoverUsers.<span class="function">addListener</span>(() { 
          <span class="function">print</span>(<span class="string">"WE DISCOVERED SOME USERS!"</span>);
          discoverUsers.discoveredUsers.<span class="function">forEach</span>((user) { 
            <span class="function">print</span>(<span class="string">"WE DISCOVERED: "</span> + user.id);
          });
        });
      </code></pre>

      <h3>By proximity</h3>
      <p>Below is an example of a GeoChannel. You can place geo-entities with a lifetime on a map of earth in the GeoChannel and query Geo-Entities efficiently.</p>
      <pre><code>
        <span class="class-name">GeoChannel</span> geoIndex = <span class="class-name">GeoChannel</span>(<span class="string">'nearby-people'</span>, metadata: <span class="string">"Hello!!"</span>);
        geoIndex.<span class="function">connect</span>(<span class="class-name">PubSubSupervisor</span>.supervisor);

        <span class="class-name">Timer</span>.<span class="function">periodic</span>(<span class="class-name">Duration</span>(milliseconds: <span class="keyword">5000</span>), (timer) {
          geoIndex.<span class="function">pingLocation</span>(
              latitude: <span class="keyword">90.7128</span> + <span class="class-name">math.Random</span>().<span class="function">nextDouble</span>() * <span class="keyword">10</span>,
              longitude: <span class="keyword">-74.0060</span> + <span class="class-name">math.Random</span>().<span class="function">nextDouble</span>() * <span class="keyword">10</span>
          );
          geoIndex.<span class="function">getNearbyEntities</span>(<span class="keyword">90.71</span>, <span class="keyword">-74.006</span>, <span class="keyword">1</span>).<span class="function">then</span>((value){
            <span class="function">print</span>(value.<span class="function">toString</span>());
          });
        });
      </code></pre>
    </div>

    <div class="section">
      <h2>Wrapup</h2>
      <p>Register your company <a href="http://localhost:3000/register">here</a>.
      Next, register your app.</p>
    </div>
  </div>
</div>
  <Footer></Footer>
</template>

<script>

import Header from '@/components/HeaderComponent.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Documentation',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

.innerdoc {
  max-width: 960px;
  margin: 0 auto;
}
.documentation {
  background: #03001e;
  color: #bbb1b8;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
}

h1 {
  color: #fdeff9;
  font-weight: 400!important;
  font-size: 2.5rem; /* Adjust as needed */
}

h2 {
  margin-top: 50px;
  color: #fdeff9;
  font-weight: 400;
  font-size: 2.5rem; /* Adjust as needed */
}

h3 {
  color: #fdeff9;
  font-weight: 300!important;
  font-size: 1.3rem; /* Adjust as needed */
}

a {
  color: #ec38bc;
}

code {
  background: none;
  color: #c5c6c7;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8rem;
}

pre {
  background: #1e1e1e;
  border-radius: 5px;
  padding: 10px;
  overflow-x: auto;
}

.keyword {
  color: #73c0c0;
}

.class-name {
  color: #ec38bc;
}

.function {
  color: #fdeff9;
}

.string {
  color: #c5c6c7;
}
</style>