<template>
  <footer class="footer">
    <div class="footer-content">
      <span class="separator">|</span>
      <div class="footer-links">
        <router-link to="/impressum"><span class="copyright">© {{ currentYear }} LoM Locality Media UG (Limited)</span></router-link>
        <span class="separator">|</span>
        <router-link to="/datenschutz">Data Protection</router-link>
        <span class="separator">|</span>
        <router-link to="/gdpr">GDPR</router-link>
        <span class="separator">|</span>
        <router-link to="/contact">Contact</router-link>
        <span class="separator">|</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

.footer {
  font-weight: 300;
  font-size: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: #fdeff9;
  background: linear-gradient(to right, #03001e, #7303c0);
  overflow: hidden;
}

.footer::before, .footer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  opacity: 0.5;
  filter: blur(10px);
  z-index: -2;
}

.footer::after {
  background: inherit;
  z-index: -1;
}

.footer-content {
  display: flex;
  align-items: center;
  z-index: 1;
}

.copyright {
  font-size: 0.9em;
  color: #fdeff9;
}

.footer-links {
  display: flex;
  justify-content: space-evenly; /* Equally space out links */
  flex-grow: 1; /* Take up all available space */
  align-items: center;
}

.footer-links a {
  color: #fdeff9;
  text-decoration: none;
}

.separator {
  margin: 0 10px;
  color: #fdeff9;
}

</style>
