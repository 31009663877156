import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-942d43e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "presentation-container" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_landing_page = _resolveComponent("landing-page")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_landing_page),
    _createElementVNode("footer", _hoisted_2, [
      _createVNode(_component_Footer)
    ])
  ]))
}