<template>
  <section>
    <Header />
    <div>
      <div class="center-horizontally">
        <button 
          v-for="artifact in artifacts" 
          :key="artifact.name" 
          :class="['artifact-button', { active: currentArtifact && currentArtifact.name === artifact.name }]" 
          @click="showArtifact(artifact)"
        >
          {{ artifact.name }}
        </button>
      </div>

      <!-- Conditionally render the content only if currentArtifact is set -->
      <div v-if="currentArtifact" class="content">
        <div v-html="currentArtifact.content"></div>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'DataProtection',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      artifacts: [
        { name: 'Deutsch', file: 'privacy_de.txt', content: '' },
        { name: 'English', file: 'privacy_en.txt', content: '' },
        { name: 'Locality Security Protocol', file: 'lsp.txt', content: '' }
      ],
      currentArtifact: null
    };
  },
  methods: {
    async showArtifact(artifact) {
      this.currentArtifact = artifact;
      if (!artifact.content) {
        try {
          const response = await fetch(artifact.file);
          artifact.content = await response.text();
        } catch (error) {
          console.error('Error loading content:', error);
        }
      }
    },
    async loadArtifacts() {
      for (let artifact of this.artifacts) {
        try {
          const response = await fetch(artifact.file);
          artifact.content = await response.text();
        } catch (error) {
          console.error(`Error loading content for ${artifact.name}:`, error);
        }
      }
      // Set default artifact to the first one after loading
      this.currentArtifact = this.artifacts[0];
    }
  },
  mounted() {
    this.loadArtifacts();
  }
};
</script>

<style scoped>
.center-horizontally {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.artifact-button {
  background: linear-gradient(to right, #03001e, #7303c0);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.artifact-button:hover {
  transform: scale(1.05);
}

.artifact-button.active {
  font-weight: bold;
  border: 2px solid #fff;
}

.content {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}
</style>
