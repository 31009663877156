<template>
  <Header activeTab="gdpr" :isOpaque="true" />
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-alert type="info" class="mb-4">
          Bedenke, dass Locality deine Nutzerdaten standardmäßig weder mit deinem Account, noch mit deiner Identität verknüpfen kann, wenn dies nicht explizit von Locality Social Cloud Entwicklern eingebaut wurde. Unter Umständen sind alle Daten, die wir dir zur Verfügung stellen können, dein Nutzername und dein öffentlicher Schlüssel. Für weitere Informationen, betrachte die Datenschutzerklärung der entsprechenden App.
        </v-alert>

        <v-card class="pa-5">
          <v-form @submit.prevent="submitForm">
            <div class="mb-3">
              <p>Gib hier deine Benutzer-ID ein.</p>
              <v-text-field
                v-model="form.user_id"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </div>

            <div class="mb-3">
              <p>Hinterlasse deine Email, wenn du willst, damit wir dich über den Prozess auf dem Laufenden halten können.</p>
              <v-text-field
                v-model="form.email"
                outlined
              ></v-text-field>
            </div>

            <div class="mb-3">
              <p>Wähle den Typ deiner Anfrage aus.</p>
              <v-select
                :items="requestTypes"
                v-model="form.requestType"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </div>

            <div class="mb-3">
              <p>Wähle dein Unternehmen aus.</p>
              <v-select
                :items="availableCompanies"
                item-title="name"
                item-value="id"
                v-model="form.company_id"
                :rules="[rules.required]"
                outlined
                label="Select Company"
                @change="onCompanyChange"
              ></v-select>
            </div>

            <div class="mb-3">
              <p>Wähle die App aus, für die du eine Anfrage stellen möchtest.</p>
              <v-select
                :items="filteredApps"
                item-title="name"
                item-value="id"
                v-model="form.app_id"
                :rules="[rules.required]"
                outlined
                label="Select App"
                @change="onAppChange"
              ></v-select>
            </div>

            <div class="mb-3">
              <p>Gib hier eine Beschreibung deines Anliegens ein.</p>
              <v-textarea
                v-model="form.details"
                :rules="[rules.required]"
                outlined
              ></v-textarea>
            </div>

            <div class="mb-3">
              <p>Dein Verifizierungstoken:</p>
              <v-text-field
                v-model="form.token"
                outlined
                readonly
              ></v-text-field>
            </div>

            <div class="mb-4">
              <p>Signiere den obigen Token mit deiner Locality App und tippe das Ergebnis hier ein. Dadurch beweist du, dass du der Besitzer des entsprechenden Locality Accounts bist.</p>
              <v-row align="center">
                <v-col cols="1" class="d-flex align-center justify-center">
                  <v-icon
                    v-if="verificationStatus === 'success'"
                    color="green"
                  >mdi-check-circle</v-icon>
                  <v-icon
                    v-if="verificationStatus === 'error'"
                    color="red"
                  >mdi-close-circle</v-icon>
                  <v-icon
                    v-if="verificationStatus === null"
                    color="grey"
                  >mdi-help-circle</v-icon>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="form.signature"
                    :outlined="true"
                    :color="verificationStatus === 'success' ? 'green' : verificationStatus === 'error' ? 'red' : ''"
                    :error="verificationStatus === 'error'"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    type="button"
                    color="primary"
                    @click="verifySignature"
                    block
                    class="mt-0"
                  >
                    Verify
                  </v-btn>
                </v-col>
              </v-row>
              <div :class="verificationClass">
                <template v-if="verificationStatus === 'success'">
                  Your account ownership has been verified.
                </template>
                <template v-if="verificationStatus === 'error'">
                  Verification has failed. Please check your signature and try again.
                </template>
              </div>
            </div>

            <div class="text-center" style="padding-bottom: 20px;">
              <v-btn type="submit" color="secondary">
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <Footer />
</template>

<script>
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/Footer.vue';
import { mapState } from 'vuex';
import axios from 'axios';
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Header,
    Footer: defineAsyncComponent(() => import('@/components/Footer.vue')),
  },
  data() {
    return {
      form: {
        user_id: '',
        email: '',
        requestType: null,
        company_id: null,
        app_id: null,
        details: '',
        token: Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0'),
        signature: '',
      },
      companies: [],
      allApps: [],
      requestTypes: [
        'Access Personal Data',
        'Rectify Data',
        'Erase Data',
        'Data Portability',
        'Restrict Processing',
        'Object to Processing',
      ],
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      verificationStatus: null, // State to track verification status
    };
  },
  computed: {
    ...mapState(['url']),
    availableCompanies() {
      return this.companies;
    },
    filteredApps() {
      if (!this.form.company_id) return this.allApps;
      return this.allApps.filter(app => app.company_id === this.form.company_id);
    },
    verificationClass() {
      if (this.verificationStatus === 'success') {
        return 'verification-success';
      } else if (this.verificationStatus === 'error') {
        return 'verification-error';
      } else {
        return '';
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        await this.fetchCompanies();
        await this.fetchApps();
      } catch (error) {
        console.error('Error loading data:', error.message);
        this.fallbackData();
      }
    },
    async fetchCompanies() {
      try {
        const response = await axios.get(`${this.url}/developers`);
        console.log((response.data))
        this.companies = [
          ...response.data
            .filter(company => company.company_id !== 'locality_media')
            .map(company => ({
              id: company.company_id,
              name: company.company_id,
            }))
        ];
        console.log("COMPANES: "+this.companies);
      } catch (error) {
        console.error('Failed to fetch companies:', error.message);
        throw error;
      }
    },
    async fetchApps() {
      try {
        const response = await axios.get(`${this.url}/apps`);
        this.allApps = [
          ...response.data
            .filter(app => app.app_id !== 'locality_social_cloud')
            .map(app => ({
              id: app.app_id,
              name: app.app_id,
              company_id: app.company_id,
            }))
        ];
      } catch (error) {
        console.error('Failed to fetch apps:', error.message);
        throw error;
      }
    },
    fallbackData() {
      this.companies = [{ id: 'locality_media', name: 'Locality Media' }];
      this.allApps = [{ id: 'locality_social_cloud', name: 'Locality Social Cloud', company_id: 'locality_media' }];
    },
    onCompanyChange() {
      this.form.app_id = null;
    },
    onAppChange() {
      if (this.form.app_id) {
        const selectedApp = this.allApps.find(app => app.id === this.form.app_id);
        if (selectedApp) {
          this.form.company_id = selectedApp.company_id;
        }
      }
    },
    async verifySignature() {
      try {
        await axios.post(`${this.url}/confirm_signature`, {
          user_id: this.form.user_id,
          token: this.form.token,
          signature: this.form.signature,
        });
        this.verificationStatus = 'success';
      } catch (error) {
        this.verificationStatus = 'error';
      }
    },
    async submitForm() {
      try {
        await axios.post(`${this.url}/submit_request`, {
          user_id: this.form.user_id,
          company_id: this.form.company_id,
          email: this.form.email,
          details: this.form.details,
          token: this.form.token,
          signature: this.form.signature,
        });
        alert('Your request has been submitted successfully!');
      } catch (error) {
        alert('Submission failed: ' + error.response.data.message);
      }
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
}
.verification-success {
  color: green;
}
.verification-error {
  color: red;
}
</style>
