<template>
  <div class="presentation-container">

    <landing-page></landing-page>
    
    <footer class="footer">
      <Footer></Footer>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import Header from '../components/HeaderComponent.vue';
import LandingPage from '../components/LandingPage.vue';
import Footer from '../components/Footer.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Header,
    LandingPage,
    Footer,
  },
});
</script>

<style scoped>
.presentation-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.presentation-wrapper {
  
  margin-top: 80px;
  transform: translate(0px, -80px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.footer {
  margin-top: auto; /* Push footer to the bottom */
}
</style>